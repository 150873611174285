// mixpanelConfig.js
import mixpanel from "mixpanel-browser";

mixpanel.init("643825a397e423d3fa4468c09c9719d7", { track_pageview: true, ignore_dnt: true });
export default mixpanel;

const trackButtonClicked = (buttonName: string) => {
  mixpanel.track("Button Clicked", {
    buttonName,
  });
};

const trackPageView = (page: string, optionalData?: any) => {
  mixpanel.track("Page View", {
    page,
    ...optionalData,
  });
};

export { trackButtonClicked, trackPageView };
