// src/pages/_app.tsx
import "../styles/globals.css";
import { api } from "../utils/api";
import type { AppType } from "next/app";
import localFont from "@next/font/local";
import Link from "next/link";
import React, { ReactNode, useState, useEffect } from "react";
import BanquetteFull from "../../public/icon/Banquette_Full.jpg";
import mixpanel, { trackButtonClicked } from "mixpanelConfig";
import { BsFillPersonFill } from "react-icons/bs";
import NextNProgress from "nextjs-progressbar";
import { useCookies } from "react-cookie";
import { CookiesProvider } from "react-cookie";
//import { SessionProvider, useSession } from "next-auth/react";
import Modal from "react-modal";
import { Toaster } from "~/components/ui/toaster";
import MySvg from "public/icon/SelectSvg";
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { useRouter } from "next/router";
import "../styles/globals.css";
import { Compass, MessageSquareMore, Ticket } from "lucide-react";
Modal.setAppElement("#__next");
const Quicksand = localFont({
  src: [
    {
      path: "../../public/fonts/Quicksand-Light.ttf",
      weight: "300",
    },
    {
      path: "../../public/fonts/Quicksand-Regular.ttf",
      weight: "400",
    },
    {
      path: "../../public/fonts/Quicksand-SemiBold.ttf",
      weight: "600",
    },
    {
      path: "../../public/fonts/Quicksand-Bold.ttf",
      weight: "700",
    },
  ],
});
type HeaderProps = {
  children: ReactNode;
};
const Header: React.FC<HeaderProps> = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [iconSize, setIconSize] = useState(16);
  const [userId, setUserId] = useState("");
  const { data: user } = api.profile.getProfile.useQuery();
  // const { data: unreadMessages } = api.chat.getUnreadMessages.useQuery();
  const router = useRouter();

  useEffect(() => {
    const fetchIdFromStorage = () => {
      try {
        const id = localStorage.getItem("token");
        if (id !== userId) {
          setUserId(id ?? "");
        }
      } catch (e) {
        console.error(e);
      }
    };
    const intervalId = setInterval(fetchIdFromStorage, 1000);
    // Fetch the id from storage initially
    void fetchIdFromStorage();
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array to run the effect only once

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    function handleResize() {
      setIconSize(getIconSize());
    }
    // Add the event listener when component mounts
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getIconSize() {
    if (window.innerWidth < 640) {
      return 18;
    } else {
      return 20; // Larger size for "sm" screens and up
    }
  }

  const decideCenterButtons = () => {
    const [hasMounted, setHasMounted] = useState(false);
    useEffect(() => {
      setHasMounted(true);
    }, []);
    if (hasMounted && userId) {
      return (
        <div className="flex w-full flex-row items-center justify-center">
          <a
            href="/myEvents"
            onClick={() => trackButtonClicked("myEvents")}
            className="hover:bg-bq-200 flex flex-row items-center justify-center rounded-full px-2 py-1.5"
          >
            <Ticket size={18} color="#29182B" />
            <p className="text-bq-950 hover:text-bq-900 ml-2 hidden text-base sm:inline-block sm:text-base">
              My Events
            </p>
          </a>
          <a
            href="/"
            onClick={() => trackButtonClicked("discover")}
            className="hover:bg-bq-200 flex flex-row items-center justify-center rounded-full px-4 py-1.5"
          >
            <Compass size={18} color="#29182B" />
            <p className="text-bq-950 hover:text-bq-900 ml-2 hidden text-base sm:inline-block sm:text-base">Discover</p>
          </a>
          <a
            href="/chat"
            onClick={() => trackButtonClicked("chat")}
            className="hover:bg-bq-200 relative flex flex-row items-center justify-center rounded-full px-2 py-1.5"
          >
            <MessageSquareMore size={18} color="#29182B" />
            <p className="text-bq-950 hover:text-bq-900 ml-2 hidden text-base sm:inline-block sm:text-base">Chat</p>
            {/* unread messages dot */}
            {/* {unreadMessages && unreadMessages.length > 0 && (
              <div className="absolute -right-1.5 top-1 ml-2 h-3 w-3 rounded-full bg-red-500" />
            )} */}
          </a>
        </div>
      );
    }
  };

  const decideRightButton = () => {
    const [hasMounted, setHasMounted] = useState(false);
    useEffect(() => {
      setHasMounted(true);
    }, []);
    if (hasMounted && userId) {
      return (
        <div className="mr-2 flex items-center self-end">
          <a
            href="/create"
            onClick={() => trackButtonClicked("ProfileLogin")}
            className="mr-2 flex h-9 w-28 flex-row items-center justify-center rounded-full md:w-40"
          >
            <p className="text-bq-950 hover:text-bq-900 text-sm hover:underline md:text-lg">+ Create Event</p>
          </a>
          <Popover>
            <PopoverTrigger>
              <button
                onClick={() => {
                  trackButtonClicked("Profile");
                  // localStorage.removeItem("token");
                }}
                className="flex h-9 w-9 items-center justify-center rounded-full"
              >
                <MySvg
                  height="40"
                  width="40"
                  color="white"
                  backgroundColor={user?.picColor === "white" ? "#29182B" : user?.picColor}
                  iconName={user?.pic}
                />
              </button>
            </PopoverTrigger>
            <PopoverContent className="bg-bq-50 flex w-48 flex-col p-0">
              <a href="/profile" className="text-bq-700 hover:text-bq-800 hover:bg-bq-200 w-full p-2 px-4">
                Profile
              </a>
              <button
                className="text-bq-700 hover:text-bq-800 hover:bg-bq-200 w-full p-2 px-4"
                onClick={() => {
                  trackButtonClicked("SignOut");
                  localStorage.removeItem("token");
                  //route to login
                  router.push("/login");
                }}
              >
                <p className="text-left">Sign Out</p>
              </button>
            </PopoverContent>
          </Popover>
        </div>
      );
    } else {
      return (
        <div className="right-4 flex self-end">
          {/* <a
            href="/create"
            onClick={() => trackButtonClicked("ProfileLogin")}
            className="mr-2 flex h-9 flex-row items-center justify-center rounded-full px-3"
          >
            <p className="text-bq-950 hover:text-bq-900 text-sm hover:underline md:text-lg">+ Create Event</p>
          </a> */}
          <a
            href="/login"
            onClick={() => trackButtonClicked("ProfileLogin")}
            className="bg-bq-700 sm:w-30 flex h-9 flex-row items-center justify-center rounded-full px-3 sm:h-9"
          >
            <BsFillPersonFill size={iconSize} color="#F8F7F8" />
            <p className="text-bq-50 hidden sm:ml-2 sm:inline-block">Sign in</p>
          </a>
        </div>
      );
    }
  };
  return (
    <div className="flex min-h-screen w-full bg-white">
      <header
        className={`fixed top-0 z-50 flex h-20 w-screen items-center justify-start bg-white px-4 sm:px-8 lg:w-screen
        ${isScrolled ? "shadow-md" : ""}
      `}
      >
        <nav className="w-full">
          <div className="flex w-full flex-row items-center justify-between">
            <a href="/" onClick={() => trackButtonClicked("Banquette Logo")}>
              <img
                src="/icon/Banquette_Full.png"
                className="sm:w-50 w-30 h-7 sm:h-7"
                style={{ objectFit: "contain" }}
                alt="Banquette Logo"
              />
            </a>
            {decideCenterButtons()}
            {decideRightButton()}
          </div>
        </nav>
      </header>
      <main className="mt-20">
        {children}
        <Toaster />
      </main>
    </div>
  );
};
const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  return (
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <NextNProgress color="#29182B" />
      <Header>
        <Component {...pageProps} />
      </Header>
    </CookiesProvider>
  );
};
export default api.withTRPC(MyApp);
